import styles from './Footer.module.css';

export const Footer = () => {
  return (
    <div className={styles.container}>
      <div className={styles.copyrightSection}>
        &copy; Latest Views App from @selexin 2024
      </div>
    </div>
  );
}
