import { Link } from 'react-router-dom';
import baseStyles from '../ui/BaseStyles.module.css';
import styles from './Header.module.css';
import useAnalyticsEventTracker from './analytics';
import { useEffect, useLayoutEffect, useState } from 'react';
import { ReactComponent as MobileNavButtonOpen } from '../ui/icons/close.svg';
import { ReactComponent as MobileNavButtonClosed } from '../ui/icons/menu.svg';
import { COLOR } from '../ui/base';

export const Header = () => {
  const mobileBreakpoint = 700;
  const [isMobile, setIsMobile] = useState(window.innerWidth < mobileBreakpoint);
  useLayoutEffect(() => {
    function updateSize() {
      setIsMobile(window.innerWidth < mobileBreakpoint)
    }
    window.addEventListener('resize', updateSize);
    updateSize();
    return () => window.removeEventListener('resize', updateSize);
  }, []);
  return (
    <div className={`${styles.container} ${isMobile ? styles.containerMobile : ''}`}>
      <div className={styles.containerInner}>
        
        <h1 className={`${baseStyles.header3} ${styles.header3}`}>
          <Link to="/">
            <img alt="" className={styles.logo} src={require('../ui/media/nft-avatar.png')} />
            Latest Views App from @selexin
          </Link>
        </h1>
       {isMobile ? <MobileMenu isDesktop={!isMobile}  /> : <DesktopMenu isDesktop={!isMobile}/>}
      </div>
    </div>
  );
}

const MenuLinks = ({onClickEvent, isDesktop}: {onClickEvent?: () => void, isDesktop: boolean}) => {
  const gaEventTracker = useAnalyticsEventTracker('Download');
  return (<>
    <MenuLink
      onClickEvent={onClickEvent}
      to="/"
      isDesktop={isDesktop}
    >
        Home
    </MenuLink>
    <MenuLink
      onClickEvent={onClickEvent}
      to="/terms"
      isDesktop={isDesktop}
    >
        Terms
    </MenuLink>
    <MenuLink
      onClickEvent={onClickEvent}
      to="/privacy"
      isDesktop={isDesktop}
    >
        Privacy
    </MenuLink>


    {/* <MenuLink
      to="#download"
      onClickEvent={()=> {
        gaEventTracker('download_header');
        onClickEvent && onClickEvent();
      }}
      isDesktop={isDesktop}
      showUnderline={false}
    >
      <PrimaryButton>Download</PrimaryButton>
    </MenuLink> */}
    </>)
}

const MenuLink = ({to, onClickEvent, isDesktop, showUnderline=true, children}: {to: string, onClickEvent?: () => void,  isDesktop: boolean, showUnderline?: boolean, children?: any}) => {
  const isCurrent = window.location.pathname === to;
return <Link
    to={to}
    onClick={onClickEvent}
    className={`${isDesktop ? styles.navLinkDesktop : styles.navLinkMobile}`}
  >
    {children}
    {showUnderline && <div
      className={`${isDesktop ? styles.navLinkDesktopCurrent : styles.navLinkMobileCurrent}`}
      style={{
        visibility: isCurrent ? 'visible' : 'hidden',
      }}
    />}
  </Link>
}

const MobileMenu = ({isDesktop}: {isDesktop: boolean}) => {
  const [isOpen, setIsOpen] = useState(false);
  return <div className={styles.mobileNav}>
    <div
      className={styles.mobileNavButton}
      onClick={() => setIsOpen(!isOpen)}
    >
      {isOpen ? <MobileNavButtonOpen fill={COLOR.primary} /> : <MobileNavButtonClosed fill={COLOR.primary} />}
    </div>
    <div
      className={styles.mobileNavLinks}
      style={{
        right: isOpen ? '0px' : '-220px',
      }}
    >
      <MenuLinks onClickEvent={() => setIsOpen(!isOpen)} isDesktop={isDesktop} />
    </div>
  </div>
}

const DesktopMenu = ({isDesktop}: {isDesktop: boolean}) => {
  return  <div className={styles.nav}>
    <MenuLinks isDesktop={isDesktop} />
  </div>
}
