import styles from './VideoCard.module.css'
import baseStyles from '../ui/BaseStyles.module.css'

type Props = {
  title: string;
  thumbnail: string;
  views: string;
  likes: string;
  comments: string;
  uploaded: string;
}

export const VideoCard = (props: Props) => {
  return (
    <div className={styles.container}>
      <div className={styles.header}>
        <h3 className={baseStyles.header2}>{props.title}</h3>
      </div>
      <div className={styles.content}>
          <img className={styles.thumbnail} alt="" src={props.thumbnail} />

          <div className={styles.countsContainer}>
            <div className={styles.countItem}>
              <span className={baseStyles.header1}>{props.views}</span> <span className={baseStyles.textSmall}>views</span>
            </div>
            <div className={styles.countItem}>
              <span className={baseStyles.header1}>{props.likes}</span> <span className={baseStyles.textSmall}>likes</span>
            </div>
            <div className={styles.countItem}>
              <span className={baseStyles.header1}>{props.comments}</span> <span className={baseStyles.textSmall}>comments</span>
            </div>
          </div>
      </div>
      <div className={styles.footer}>
        <p className={baseStyles.textSmall}>Uploaded {props.uploaded}</p>
      </div>
    </div>
  )

}