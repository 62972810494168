import styles from './App.module.css';
import {
  createBrowserRouter,
  RouterProvider,
} from "react-router-dom";
import { Home } from './pages/Home';
import {Terms} from "./pages/Terms";
import {Privacy} from "./pages/Privacy";

const router = createBrowserRouter([
  {
    path: "/",
    element: <Home />,
  },
  // {
  //   path: "/faq",
  //   element: <FAQ />,
  // },
  // {
  //   path: "/contact",
  //   element: <Contact />,
  // },
  {
    path: "/terms",
    element: <Terms />,
  },
  {
    path: "/privacy",
    element: <Privacy />,
  },
    {
    path: "*",
    element: <Home />,
    action: () => {
      return {
        redirect: "/",
      };
    }
  },
]);


const App = () => {
  return (
    <div className={styles.container}>
      <RouterProvider router={router}  />
    </div>
  );
}

export default App;
