import { Footer } from "./Footer";
import { Header } from "./Header";
import styles from "./Home.module.css";
import baseStyles from '../ui/BaseStyles.module.css';
import { ScrollRestoration } from "react-router-dom";
import { VideoCard } from "../components/VideoCard";

export const Home = () => {
  return (
    <div className={styles.container}>
      <ScrollRestoration />
      <div className={styles.bodyContainer}>
        <Header />

        <div className={baseStyles.section}>
        <h2 className={baseStyles.header1}>Latest Views App from @selexin</h2>
          <h2 className={baseStyles.header3}>See the view count of your latest videos across multiple platforms all in one place!</h2>
        </div>

        <div className={`${baseStyles.section} ${styles.videoPreviewContainer}`}>
          <div className={styles.videoPreview}>
            <VideoCard
              title="TikTok"
              thumbnail="https://p16-sign-sg.tiktokcdn.com/tos-alisg-p-0037/2724a010dec943b99bbe065aef3ef732_1709539808~tplv-dmt-logom:tos-alisg-i-0068/ocD5QEaIDyWHYCgWgEfdAqAA9F4xAlBEof1RAl.image?x-expires=1709751600&x-signature=8x9pgz93utiCa3FuMdh91gOv48k%3D"
              views="10.1K"
              likes="723"
              comments="212"
              uploaded="4 hours ago"
            />

            <VideoCard
              title="YouTube"
              thumbnail="https://p16-sign-sg.tiktokcdn.com/tos-alisg-p-0037/2724a010dec943b99bbe065aef3ef732_1709539808~tplv-dmt-logom:tos-alisg-i-0068/ocD5QEaIDyWHYCgWgEfdAqAA9F4xAlBEof1RAl.image?x-expires=1709751600&x-signature=8x9pgz93utiCa3FuMdh91gOv48k%3D"
              views="869"
              likes="231"
              comments="15"
              uploaded="3 hours ago"
            />

            <VideoCard
              title="Instagram"
              thumbnail="https://p16-sign-sg.tiktokcdn.com/tos-alisg-p-0037/2724a010dec943b99bbe065aef3ef732_1709539808~tplv-dmt-logom:tos-alisg-i-0068/ocD5QEaIDyWHYCgWgEfdAqAA9F4xAlBEof1RAl.image?x-expires=1709751600&x-signature=8x9pgz93utiCa3FuMdh91gOv48k%3D"
              views="635"
              likes="143"
              comments="10"
              uploaded="58 minutes ago"
            />
          </div>
          <div className={styles.videoPreviewFooter}>
            <p className={baseStyles.textSmall}>*Example data shown</p>
          </div>
        </div>
        
        <div className={baseStyles.section}>
          <h3 className={baseStyles.header2}>How does it work?</h3>
          <p>
            Login with your TikTok, YouTube, and/or Instagram accounts,
            and Latest Views will keep track of your most recent posted video!
          </p>
          <p>
            Latest Views will display the up-to-date View, Like and Comment numbers for your latest posted videos.
          </p>
          <p>
            Now you can save time by not needing to open each app and individually to see your video stats!
          </p>
        </div>

        <div className={`${baseStyles.section} ${styles.comingSoon}`}>
          <h3 className={baseStyles.header2}>How do I login?</h3>
          <p>
            Latest Views is currently in beta while we work with TikTok, YouTube, and Instagram to finalise API access.
          </p>
          <p>
            To sign up to the waitlist please email <a href="mailto:latestviews@selexin.co">latestviews@selexin.co</a>.
          </p>
        </div>

        <div className={baseStyles.section}>
          <h3 className={baseStyles.header2}>Contact</h3>
          <p>
            For all enquiries, please email <a href="mailto:latestviews@selexin.co">latestviews@selexin.co</a>.
          </p>
          <p>
            You can also view our <a href="/terms">Terms of Use</a> and <a href="/privacy">Privacy Statement</a>.
          </p>
        </div>
      </div>
      <Footer />  
    </div>
  );
};
