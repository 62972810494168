export const COLOR = {
    primary: '#000',
    primary90: 'rgba(0, 0, 0, 0.76)',
    primary80: 'rgba(0, 0, 0, 0.83)',

    background: '#FFF',
    background90: '#fffffffa',
    background80: '#ffffffbd',
    /* disabled button text */
    background70: '#11111183',

    feature: '#6433FE',
    feature90: '#6433FEc1',
    /* disabled button bg */
    feature80: '#6433FE8e',
    feature70: '#6433FE53',

    error: '#EB0000',
}

export const TEXTSIZE = {
    tiny: 10,
    small: 12,
    medium: 16,
    large: 21,
    xlarge: 28,
    xxlarge: 34,
}

export const FONTFAMILY = {
    header: 'Kollektif',
    body: 'OpenSans-Regular',
}
