import {ScrollRestoration} from "react-router-dom";
import styles from "./Terms.module.css";
import ReactMarkdown from 'react-markdown'
import remarkGfm from 'remark-gfm'
// @ts-ignore
import source from "../markdown/privacy.md";
import {useEffect, useState} from "react";
import {Header} from "./Header";
import {Footer} from "./Footer";

export const Privacy = () => {
    const [markdown, setMarkdown] = useState<string>("");
    useEffect(() => {
        fetch(source).then((response) => response.text()).then((text) => {
            setMarkdown(text);
        });
    }, [])
  return (
    <div className={styles.container}>
        <ScrollRestoration />
        <Header />
        {markdown !== "" && (
            <>
                <div className={styles.terms}>
                    <ReactMarkdown remarkPlugins={[remarkGfm]} children={markdown} />
                </div>
                <Footer />
            </>
        )}

    </div>
  )
}
