const useAnalyticsEventTracker = (category="Blog category") => {
  const eventTracker = (action = "test action", label = "test label") => {
    // @ts-ignore
    if (window.gtag) {
    // @ts-ignore
      window.gtag('event', action, {
        'event_category': category,
        'event_label': label
      });
    }
  }
  return eventTracker;
}
export default useAnalyticsEventTracker;
